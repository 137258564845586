(<template>
  <accordion ref="faiAccordion"
             :initial-opened-state="isAssignedUser || isSubtaskProactive">
    <div slot="header"
         class="default-accordion__header subtask-accordion__wrapper"
         :style="accordionStyle">
      <div class="subtask-accordion_title">
        <p class="inter-list__header-text">{{ titleText }}</p>
        <p class="subtask-type_title">{{ subtitleText }}: <span>{{ subtaskType }}</span></p>
      </div>
      <tt-avatar v-if="visibleToNotAssigned"
                 class="subtask-avatar"
                 :avatar="subtask.seller.avatar" />
    </div>
    <template slot="content">
      <div class="default-accordion__content">
        <div class="subtask-info">
          <table class="subtask-info__table subtask-info-table">
            <thead>
              <tr>
                <th v-for="(item, i) in tableDataTitle"
                    :key="item + i"
                    class="translation-text-title">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="visibleToNotAssigned"
                    class="translation-text-bold"
                    :data-label="supplierText">{{ subtask.seller.name || '-' }}</td>
                <td v-if="showDeadlineByStatus"
                    class="translation-text-bold"
                    :class="{'short-noticed-icon short-noticed-deadline': isShortNotice}"
                    :data-label="deadlineText">{{ subtaskDeadline || '-' }}
                </td>
                <td class="translation-text-bold"
                    :data-label="noWordsText">{{ subtask.countOfWords || '-' }}</td>
                <td v-if="isAssignedUser"
                    class="translation-text-bold"
                    :data-label="pricePerText">{{ pricePerWord }}</td>
                <td v-if="isAssignedUser"
                    class="translation-text-bold"
                    :data-label="estimateTotalText">{{ sellerQuote }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="!isFinishedProject"
               class="subtask-info__content">
            <h5 class="subtask-info__internal-title translation-text-title">{{ internalMessageText }}</h5>
            <p class="subtask-info__internal-message translation-text-primary">{{ subtask.instructionsFromProjectManager || $pgettext('translation', 'No Message') }}</p>
          </div>
          <div v-if="!isFinishedProject && subtask.reviewCommentFromProjectManager"
               class="subtask-info__content">
            <h5 class="subtask-info__internal-title translation-text-title">{{ reviewCommentText }}</h5>
            <p class="subtask-info__internal-message translation-text-primary">{{ subtask.reviewCommentFromProjectManager }}</p>
          </div>
          <div v-if="isShortNotice"
               class="subtask-info__short-noticed">
            <span class="subtask-info__short-noticed-title short-noticed-icon">{{ $gettext('Short notice') }}</span>
            <p class="subtask-info__short-noticed-text">{{ shortNoticedWarningText }}</p>
          </div>
          <subtask-actions :target-language="targetLanguage"
                           :subtask="subtask" />
        </div>
      </div>
    </template>
  </accordion>
</template>)

<script>
  import Accordion from '@/components/shared_components/Accordion';
  import SubtaskActions from '@/components/project_components/one_project/subtask_actions/SubtaskActions';
  import helpers from '@/helpers';
  import {mapGetters} from 'vuex';

  export default {
    components: {
      accordion: Accordion,
      'subtask-actions': SubtaskActions
    },
    props: {
      subtask: {
        type: Object,
        default: () => {}
      },
      targetLanguageId: {
        type: Number,
        default: 0
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsNotInterpreter']),
      ...mapGetters('OneProjectStore', ['isFinishedProject']),
      languagesList() { return this.$store.state.selectsData.language || []; },
      targetLanguage() {
        return this.languagesList.find((lang) => {
          return lang.id === this.targetLanguageId;
        }).name;
      },
      isAssignedUser() {
        return this.subtask.seller.uid === this.userUid;
      },
      isSubtaskProactive() {
        return this.subtask.status === 'proactive_access';
      },
      isShortNotice() {
        return this.subtask.requestedShortNotice;
      },
      visibleToNotAssigned() {
        const statuses = ['accepted', 'in_progress', 'review_requested', 'changes_required', 'finished'];
        if (!this.isAssignedUser) {
          return statuses.includes(this.subtask.status);
        } else {
          return true;
        }
      },
      showDeadlineByStatus() {
        const statuses = ['rejected', 'cancelled', 'finished'];
        return !statuses.includes(this.subtask.status);
      },
      tableDataTitle() {
        if (this.isAssignedUser && this.showDeadlineByStatus) {
          return [
            this.supplierText, this.deadlineText, this.noWordsText, this.pricePerText, this.estimateTotalText
          ];
        } else if (this.isAssignedUser) {
          return [
            this.supplierText, this.noWordsText, this.pricePerText, this.estimateTotalText
          ];
        } else {
          if (this.visibleToNotAssigned) {
            return [
              this.supplierText, this.deadlineText, this.noWordsText
            ];
          } else {
            return [
              this.deadlineText, this.noWordsText
            ];
          }
        }
      },
      isSuitableSeller() {
        return this.subtask.isSuitable;
      },
      getStatusText() {
        return helpers.getProjectInfo.getSubtaskStatus(this, this.subtask.status, this.isSuitableSeller);
      },
      accordionStyle() {
        return helpers.getProjectInfo.accordionStatusStyles(this.subtask.status);
      },
      subtaskType() {
        return helpers.getProjectInfo.subtaskType(this, this.subtask.subtaskType);
      },
      titleText() {
        return `#${this.subtask.publicId} - ${this.$pgettext('translation', this.getStatusText)}`;
      },
      subtitleText() {
        return this.$pgettext('translation', 'Assignment');
      },
      supplierText() {
        return this.$pgettext('translation', 'Supplier');
      },
      deadlineText() {
        return this.$pgettext('translation', 'Deadline');
      },
      noWordsText() {
        return this.$pgettext('translation', 'Number of words');
      },
      pricePerText() {
        return this.$pgettext('translation', 'Price per word');
      },
      estimateTotalText() {
        return this.$pgettext('translation', 'Estimated Total');
      },
      internalMessageText() {
        return this.$pgettext('translation', 'Internal message from project manager');
      },
      reviewCommentText() {
        return this.$pgettext('translation', 'Review comment from project manager');
      },
      shortNoticedWarningText() {
        return this.$pgettext('translation', 'Short notice assignments are paid with a higher rate when it is delivered in time and approved by the customer.');
      },
      subtaskDeadline() {
        const deadline = this.subtask.internalDeadline;
        return deadline ? this.$moment(deadline).format('D MMM. @HH:mm') : '-';
      },
      pricePerWord() {
        if (this.subtask.sellerQuoteAmount && this.subtask.countOfWords) return (this.subtask.sellerQuoteAmount / this.subtask.countOfWords).toFixed(2) + ' NOK';
        else return '-';
      },
      sellerQuote() {
        return this.subtask.sellerQuoteAmount ? this.subtask.sellerQuoteAmount + ' NOK' : '-';
      }
    }
  };
</script>

<style scoped>
.default-accordion__content {
  overflow: hidden;
  border: 2px solid #fff;
  border-top: none;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}

.accordion {
  margin-bottom: 20px;
}

.subtask-info {
  padding: 20px;
  background-color: #fff;
}

.subtask-info__table {
  margin-bottom: 10px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d3d5de;
}

.subtask-info-table {
  width: 100%;
  border-spacing: 4px;
}

.subtask-info-table td,
.subtask-info-table th {
  text-align: left;
}

.subtask-info-table td:first-child,
.subtask-info-table th:first-child {
  width: 100%;
  max-width: 30%;
}

.subtask-info-table th:nth-child(4),
.subtask-info-table td:nth-child(4),
.subtask-info-table th:last-child,
.subtask-info-table td:last-child {
  text-align: right;
}

.subtask-info__content {
  margin-bottom: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #d3d5de;
}

.subtask-accordion__wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
}

.subtask-avatar {
  width: 27px;
  height: 27px;
}

.subtask-type_title {
  font-size: 14px;
}

.subtask-type_title span {
  font-weight: normal;
  font-size: 14px;
}

.short-noticed-deadline {
  padding-right: 18px;
  background-position: right center;
  white-space: nowrap;
}

.subtask-info__short-noticed {
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 4px;
  background: #f5771d1a;
  font-size: 12px;
  line-height: 16px;
}

.subtask-info__short-noticed-title {
  padding-left: 24px;
  background-position: left 0 center;
  font-weight: bold;
}

.subtask-info__short-noticed-text {
  padding-left: 24px;
}

.short-noticed-icon {
  background-image: url(~@assets/imgs/salita/projects/short-notice-icon.svg);
  background-size: 16px 16px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 600px) {
  .subtask-info-table {
    border: 0;
  }

  .subtask-info-table thead {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: none;
  }

  .subtask-info-table td:first-child,
  .subtask-info-table th:first-child {
    max-width: 100%;
  }

  .subtask-info-table td {
    position: relative;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    text-align: right;
  }

  .subtask-info-table td::before {
    content: attr(data-label);
    float: left;
    color: #5a5964;
    font-weight: 400;
    font-size: 12px;
  }

  .subtask-info-table td:last-child {
    border-bottom: 0;
  }

  .short-noticed-deadline {
    padding-right: 20px;
  }
}
</style>

<style>
/* Do we need these styles? */

/* .translation-text-title {
  color: #5a5964;
  font-weight: 400;
  font-size: 10px;
} */

/* .translation-text-bold {
  color: #000;
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
} */

/* .translation-text-primary {
  color: #000;
  font-size: 14px;
} */
</style>
