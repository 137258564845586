(<template>
  <accordion ref="faiAccordion"
             :initial-opened-state="taskHasCurrentUser || !isSubtaskAssigned || isSubtaskProactive">
    <div slot="header"
         class="default-accordion__header task-accordion__wrapper"
         :style="accordionStyle">
      <div class="subtask-accordion_title">
        <p class="inter-list__header-text">{{ titleText }}<span class="task-accordion_lang-pair">{{ taskLanguagePair }}</span></p>
        <p v-if="subtaskType"
           class="task-type_title">{{ subtitleText }}: <span>{{ subtaskType }}</span></p>
      </div>
      <div class="task-avatars__wrapper">
        <tt-avatar v-for="subtask in filteredAvatars"
                   :key="subtask.publicId"
                   class="task-avatar"
                   :avatar="subtask.seller.avatar" />
      </div>
    </div>
    <template slot="content">
      <div v-if="isSubtaskAssigned"
           class="default-accordion__content">
        <single-project-subtask v-for="subtask in filteredSubtasks"
                                :key="subtask.publicId"
                                :target-language-id="task.targetLanguageId"
                                :subtask="subtask" />
      </div>
      <sk-note v-else
               class="default-accordion__content task-not-assigned__message">{{ notAssignedText }}</sk-note>
    </template>
  </accordion>
</template>)

<script>
  import Accordion from '@/components/shared_components/Accordion';
  import SingleProjectSubtask from '@/components/project_components/one_project/SingleProjectSubtask';
  import helpers from '@/helpers';
  import {mapGetters} from 'vuex';

  export default {
    components: {
      accordion: Accordion,
      'single-project-subtask': SingleProjectSubtask
    },
    props: {
      task: {
        type: Object,
        default: () => {}
      },
      sourceLanguageId: {
        type: Number,
        default: 0
      },
      initiallyOpened: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      languagesList() { return this.$store.state.selectsData.language || []; },
      targetLanguage() {
        return this.languagesList.find((lang) => {
          return lang.id === this.task.targetLanguageId;
        }).name;
      },
      sourceLanguage() {
        const foundLanguage = this.languagesList.find((lang) => {
          return lang.id === this.sourceLanguageId;
        });

        return foundLanguage ? foundLanguage.name : '';
      },
      getStatusText() {
        return helpers.getProjectInfo.getTaskStatus(this, {status: this.task.status});
      },
      accordionStyle() {
        return helpers.getProjectInfo.accordionStatusStyles(this.task.status);
      },
      subtaskType() {
        const subTaskTypes = this.task.subtasks?.map((subtask) => {
          return helpers.getProjectInfo.subtaskType(this, subtask.subtaskType);
        });
        return subTaskTypes.join(', ');
      },
      filteredSubtasks() {
        return this.task?.subtasks.filter((subtask) => subtask.seller.uid === this.userUid
          || subtask.status === 'proactive_access');
      },
      filteredAvatars() {
        return this.filteredSubtasks.filter((subtask) => {
          const statuses = ['accepted', 'in_progress', 'review_requested', 'changes_required', 'finished'];
          if (!this.isAssignedUser) {
            if (statuses.includes(subtask.status)) return subtask;
          } else {
            return subtask;
          }
        });
      },
      isSubtaskAssigned() {
        return this.task.subtasks && this.task.subtasks.length;
      },
      isSubtaskProactive() {
        return this.filteredSubtasks.some((subtask) => subtask.status === 'proactive_access');
      },
      taskHasCurrentUser() {
        return this.task?.subtasks.some((subtask) => subtask.seller.uid === this.userUid);
      },
      titleText() {
        return `#${this.task.publicId}  ${this.$pgettext('translation', this.getStatusText)} `;
      },
      taskLanguagePair() {
        return `${this.sourceLanguage} - ${this.targetLanguage}`;
      },
      subtitleText() {
        return this.$pgettext('translation', 'Assignment');
      },
      notAssignedText() {
        return this.$pgettext('translation', 'Please wait for the project manager to create sub-tasks');
      }
    }
  };
</script>

<style scoped>
.default-accordion__content {
  overflow: hidden;
  padding: 10px 15px;
  border: 2px solid #fff;
  border-top: none;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}

.task-not-assigned__message {
  padding: 20px 15px;
}

.accordion {
  margin-bottom: 20px;
}

.subtask-info {
  padding: 20px;
  background-color: #fff;
}

.subtask-info__table {
  padding-bottom: 16px;
  border-bottom: 1px solid #d3d5de;
}

.subtask-info-table {
  width: 100%;
}

.subtask-info-table td,
.subtask-info-table th {
  text-align: left;
}

.subtask-info-table td:first-child,
.subtask-info-table th:first-child {
  width: 100%;
  max-width: 30%;
}

.subtask-info-table th:nth-child(4),
.subtask-info-table td:nth-child(4),
.subtask-info-table th:last-child,
.subtask-info-table td:last-child {
  text-align: right;
}

.subtask-info__content {
  margin-bottom: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d3d5de;
}

.task-accordion__wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
}

.task-avatar {
  width: 27px;
  height: 27px;
  margin-left: 4px;
}

.task-type_title {
  font-size: 14px;
}

.task-type_title span {
  font-weight: normal;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .subtask-info-table {
    border: 0;
  }

  .subtask-info-table thead {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: none;
  }

  .subtask-info-table td:first-child,
  .subtask-info-table th:first-child {
    max-width: 100%;
  }

  .subtask-info-table td {
    position: relative;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    text-align: right;
  }

  .subtask-info-table td::before {
    content: attr(data-label);
    float: left;
    color: #5a5964;
    font-weight: 400;
    font-size: 12px;
  }

  .subtask-info-table td:last-child {
    border-bottom: 0;
  }
}
</style>

<style>
.task-accordion_lang-pair {
  font-weight: normal !important;
  font-size: 16px !important;
}

.translation-text-title {
  color: #5a5964;
  font-weight: 400;
  font-size: 10px;
}

.translation-text-bold {
  color: #000;
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
}

.translation-text-primary {
  color: #000;
  font-size: 14px;
}
</style>
